<template>
	<div class="pbody-category">
		<div class="ads-class ad-bg" :class="{'ads-class-f' : !isMobileLayout, 'ads-class-m' : isMobileLayout}">
			<p style="margin: 0px; color: #ffffff; font-size: 10px; text-align: center;">Advertisement</p>
			<div ></div>
		</div>
		<loading v-if="uniqueCategories.length == 0"></loading>
		<div v-if="uniqueCategories.length > 0 && !isMobileLayout" class="grid-all pbody-block">
			<div class="block-row-f">
				<div class="block-title">{{categoryTitle}}</div>
				<div class="block-grid">
					<game-item v-for="game in categoryGames" :game="game" :key="game.handle" :showTitle="false" height="114"></game-item>
				</div>
			</div>
		</div>
		<div v-if="uniqueCategories.length > 0 && isMobileLayout" class="grid-all-mo">
			<div class="block-title-mo">{{categoryTitle}}</div>
			<div class="block-grid-mo">
				<game-item v-for="game in categoryGames" :game="game" :key="game.handle" :showTitle="false" style="height: 100%; width: 100%; box-shadow: none; border-radius: 4px; display: flex; border: none; padding: 0px;"></game-item>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapState
	} from 'vuex'
	import GameItem from '@/components/common/game-item'
	import Loading from '@/components/common/loading'
	export default {
		inject: ['app'],
		components: {
			Loading,
			GameItem
		},
		computed: {
			...mapGetters({
				uniqueCategories: 'games/uniqueCategories',
			}),
			...mapState({
				games: state => state.games.games
			}),
			categoryTitle() {
				const item = this.uniqueCategories.find(v => v.category_handle == this.$route.params.id)
				return item && item.category
			},
			categoryGames() {
				return this.games.filter(v => v.category_handle == this.$route.params.id)
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		},
		watch: {
			uniqueCategories(newVal, oldVal) {
				if (newVal.length > 0) {
					const existed = newVal.find(v => v.category_handle == this.$route.params.id)
					if (existed === undefined) {
						this.$router.push('/')
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.pbody-category {
		margin: 10px auto 0 auto;
		max-width: 1410px;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex:1;
	}

	.grid-all {
		padding-bottom: 12px;
		margin: 0 auto;
		width: 100%;
	}

	.block-grid {
		min-width: 1120px;
	}

	.grid-all-mo {
		padding: 10px 10px 0px 10px;
	}

	.ads-class {
		text-align: center;
		margin-bottom: 10px;
		border-radius: 10px;
		height: 250px;
		overflow: hidden;
	}
</style>